/*
  Theme and global variables
*/

html,
body {
  height: 100%;
}

html {
  overflow-y: scroll;
  --audio-player-border: 1px solid #aaa;
  --input-field-default-font-color: #000;
  --input-field-selection-background: #0078d7;
  --input-field-selection-font-color: #fff;
  --input-field-unselected-background-color: #fff;
  --player-outer-cell-background: rgb(211, 211, 211);
  --player-gradient-top: hsl(0, 0%, 90%);
  --player-gradient-bottom: hsl(0, 0%, 82%);
  --footer-gradient-top: hsl(0, 0%, 92%);
  --footer-gradient-bottom: hsl(0, 0%, 85%);
  --player-inner-cell-background: rgb(245, 245, 245);
  --songs-table-alternating-row-color: #eee;
  /* --songs-table-header-background-color: #ddd; */
  --songs-table-header-background-color: #fff;
  --songs-table-header-border: 1px solid #aaa;
  --selection-default-color: hsl(214, 97%, 57%);
  --selection-font-color: #fff;
  --star-default-color: #077efc;
  --star-selected-color: #fff;
  --star-nonstar-outline: grey;
  --artist-td-width: 135px;
  --songname-td-width: 300px;
  --date-td-width: 120px;
  --length-td-width: 80px;
  --plays-td-width: 47px;
  --play-icon-td-width: 25px;
  --size-td-width: 60px;
  --stars-td-width: 80px;
  --playlists-width: 197px;
  --passive-playlist-select-color: hsl(0, 0%, 85%);
}

form {
  display: inline;
}

img {
  padding: 0;
}

/*
  Custom styles
*/
body {
  font-family: sans-serif;
  /* color: hsl(0, 0%, 94%); */
}

/* button {
  margin: 1px;
  padding: 5px;
  background: transparent;
  cursor: pointer;
} */

/* 
button:last-child {
  border-right: 1px solid #2884FC;
} */

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

h1 {
  flex: 1;
  font-size: 2rem;
  line-height: var(--header-height);
  text-align: center;
}

@media (max-width: 25rem) {
  h1 {
    font-size: 1.25rem;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

input[type='text'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  margin: 0;
  padding: 0;
  background: var(--input-field-unselected-background-color);
  color: var(--input-field-default-font-color);
  border: none;
  font-size: 12px;
}

/* For IE11 and below, you might need additional styles due to lack of support for appearance */
input[type='text']::-ms-clear,
input[type='text']::-ms-reveal {
  display: none;
}

input::selection {
  /* background: rgb(56, 56, 255);  */
  background: var(--input-field-selection-background);
  color: var(--input-field-selection-font-color);
}

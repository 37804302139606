html, body {
  height: 100%;
}

html {
  --audio-player-border: 1px solid #aaa;
  --input-field-default-font-color: #000;
  --input-field-selection-background: #0078d7;
  --input-field-selection-font-color: #fff;
  --input-field-unselected-background-color: #fff;
  --player-outer-cell-background: #d3d3d3;
  --player-gradient-top: #e6e6e6;
  --player-gradient-bottom: #d1d1d1;
  --footer-gradient-top: #ebebeb;
  --footer-gradient-bottom: #d9d9d9;
  --player-inner-cell-background: #f5f5f5;
  --songs-table-alternating-row-color: #eee;
  --songs-table-header-background-color: #fff;
  --songs-table-header-border: 1px solid #aaa;
  --selection-default-color: #2783fc;
  --selection-font-color: #fff;
  --star-default-color: #077efc;
  --star-selected-color: #fff;
  --star-nonstar-outline: grey;
  --artist-td-width: 135px;
  --songname-td-width: 300px;
  --date-td-width: 120px;
  --length-td-width: 80px;
  --plays-td-width: 47px;
  --play-icon-td-width: 25px;
  --size-td-width: 60px;
  --stars-td-width: 80px;
  --playlists-width: 197px;
  --passive-playlist-select-color: #d9d9d9;
  overflow-y: scroll;
}

form {
  display: inline;
}

img {
  padding: 0;
}

body {
  font-family: sans-serif;
}

.wrapper {
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

h1 {
  font-size: 2rem;
  line-height: var(--header-height);
  text-align: center;
  flex: 1;
}

@media (max-width: 25rem) {
  h1 {
    font-size: 1.25rem;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

input[type="text"] {
  appearance: none;
  background: var(--input-field-unselected-background-color);
  color: var(--input-field-default-font-color);
  border: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

input[type="text"]::-ms-clear {
  display: none;
}

input[type="text"]::-ms-reveal {
  display: none;
}

input::selection {
  background: var(--input-field-selection-background);
  color: var(--input-field-selection-font-color);
}

/*# sourceMappingURL=index.1e9a7f98.css.map */
